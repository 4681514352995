/* General styles */
.home-main {
  text-align: center;
}

/* Header section */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 60px;
}

.contact-info {
  display: flex;
  align-items: center;
}

.contact-item {
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.contact-item i {
  margin-right: 5px;
}

.book-button {
  background: linear-gradient(to right, #0073e6, #00c6ff);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
}

.book-button:hover {
  background-color: #005bb5;
}

/* Update hero section */
.hero {
  background-color: #c4edf8;
  color: black;
  padding: 80px 20px;
  text-align: center;
  border-radius: 15px;
}

.hero-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.hero-text {
  flex: 1;
  text-align: left;
  padding: 20px;
}

.hero-image {
  flex: 1;
  text-align: center;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.hero-image img:hover {
  transform: scale(1.1);
}

.apply-button, .book-button {
  background: linear-gradient(to right, #0073e6, #00c6ff);
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  text-decoration: none;
  margin: 10px;
  display: inline-block;
}

.apply-button:hover, .book-button:hover {
  background-color: #004181;
}

/* Partners section */
.partners-section {
  padding: 50px 20px;
  background-color: #f5f5f5;
}

.partners-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
}

.partners-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.partners-logos img {
  max-height: 80px;
  max-width: 100%;
  object-fit: contain;
}


/* Experience Section */
.experience-difference {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 1em;
  background-color: #fff; 
}

.experience-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visual appeal */
  background-color: #c4edf8; /* Ensure text is readable */
  border-radius: 10px; /* Optional: Add rounded corners */
}

.experience-difference .image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience-difference .rounded-image {
  width: 300px; /* Adjust size as needed */
  height: auto; /* Keep the height auto to maintain aspect ratio */
  border-radius: 15px; /* Rounded corners */
  object-fit: cover; /* Ensure image covers the area without distortion */
  border: 5px solid #f0f0f0; /* Optional border */
}

.image-container img:hover {
  transform: scale(1.1);
}

.text-container {
  flex: 2;
  margin-left: 2em;
}

.text-container h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 0.5em;
}

.text-container p {
  font-size: 1.2em;
  color: #666;
  line-height: 1.5em;
}



/* Services section */
.services-section {
  background-color: #f5f5f5;
  padding: 50px 20px;
}

.services-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
}

.service-card {
  background-color: #c4edf8;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-card img {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
}

.service-card h3 {
  font-size: 24px;
  margin: 20px 0 10px;
  text-align: center;
}

.service-card p {
  font-size: 16px;
  color: #666;
  text-align: center;
}

/* Image comparison section */
.image-comparison-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #fff; /* Background color to match overall theme */
  
}

.image-comparison-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2em 1em;
  background-color: #c4edf8;
  border-radius: 10px;
}

.comparison-column {
  width: 45%; /* Adjust width as needed */
  margin: 0 20px; /* Space between columns */
  display: flex; /* Allows vertical alignment */
  flex-direction: column; /* Align items in a column */
  justify-content: space-between; /* Distributes space between top and bottom */
}

.comparison-column img {
  max-width: 100%; /* Ensure images are responsive */
  height: auto; /* Keep aspect ratio */
  flex-grow: 1; /* Allow images to grow and fill the space */
  object-fit: contain; /* Prevents cropping */
  border-radius: 10px; /* Matches rounded corners */
}


.comparison-column h3 {
  font-size: 24px; /* Heading size */
  margin: 10px 0; /* Margin for spacing */
  color: #333; /* Consistent text color */
}

.comparison-column p {
  font-size: 16px; /* Consistent text size */
  color: #666; /* Subtle text color */
  line-height: 1.5; /* Line height for readability */
}

/* About Us section */
.about-us {
  padding: 50px 20px;
}

.about-us h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  color: black;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.social-icons a {
  color: #333;
  font-size: 24px;
  margin: 0 15px;
  transition: color 0.3s ease;
}

social-icons a:hover {
  color: #0073e6;
}

.about-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  text-align: center;
  background-color: #c4edf8;
  border-radius: 15px;
}

.about-item h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.about-item p {
  font-size: 16px;
  color: #666;
  align-items: center;
}



/* Facility services section */
.facility-services {
  padding: 50px 20px;
  background-color: #f5f5f5;
}

.facility-services h2 {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
}

.facility-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  text-align: center;
}

.facility-item {
  background-color: #c4edf8;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s;
}

.facility-item:hover {
  transform: translateY(-5px);
}

.facility-item i {
  font-size: 24px;
  color: #0073e6;
  margin-bottom: 10px;
}

.facility-item h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.facility-item p {
  font-size: 16px;
  color: #666;
}


/* Media Queries */
@media (max-width: 1200px) {
  .hero-content {
    flex-direction: column;
  }
  .experience-container {
    flex-direction: column;
    text-align: center;
  }
  .text-container {
    margin-left: 0;
  }
  .partners-logos {
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .contact-info {
    margin-top: 15px;
    flex-direction: column;
    text-align: center;
  }

  .contact-item {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .hero-content {
    padding: 40px 20px;
  }

  .hero-text {
    padding: 20px 0;
    text-align: center;
  }

  .hero-image {
    margin-top: 20px;
  }

  .about-info {
    grid-template-columns: 1fr;
  }
  .facility-grid {
    grid-template-columns: 1fr; /* Single column for mobile */
  }

  .comparison-column {
    width: 100%; /* Stack columns on small screens */
    margin: 10px 0; /* Space between stacked items */
  }
}

@media (max-width: 480px) {
  .hero-content {
    padding: 30px 10px;
  }

  .contact-info {
    display: none !important; /* Hides the contact info section on mobile */
  }

  .hero-text {
    padding: 10px 0;
    margin-top:-4rem;
  }

  .service-cards {
    grid-template-columns: 1fr;
  }
  .facility-grid {
    grid-template-columns: 1fr; /* Single column for mobile */
  }
}

