/* ApplyForJob.css */

.sharp-clean-logo {
  width: 150px; /* Adjust size as needed */
  margin-bottom: 20px; /* Adjust margin as needed */
}

.apply-for-job-container {
  position: relative;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8); /* Transparent background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(10px); /* Blur effect for modern look */
}

.apply-for-job-logo-container {
  display: flex;
  justify-content: center;
}

.apply-for-job-form {
  display: flex;
  flex-direction: column;
}

.apply-for-job-form label {
  margin-bottom: 10px;
  font-weight: bold;
}

.apply-for-job-form input,
.apply-for-job-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.apply-for-job-form input[type="file"] {
  padding: 0;
}

.apply-for-job-form button {
  padding: 10px 20px;
  background-color: #004181;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.apply-for-job-form button:hover {
  background: #218838;
}

.apply-for-job-bg-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-position: center;
  opacity: 0.4; /* Adjust opacity for transparency */
  border-radius: 8px;
}

.form-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}

.form-message.success {
  background-color: #d4edda;
  color: #155724;
}

.form-message.error {
  background-color: #f8d7da;
  color: #721c24;
}
