/* AboutUs.css */

.about-us-page {
  position: relative;
  max-width: 900px;
  margin: 50px auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  text-align: center;
  color: #333;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  border-radius: 10px;
}

.background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
  transition: opacity 0.5s ease;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 115, 230, 0.8), rgba(0, 193, 255, 0.8));
  z-index: -1;
  border-radius: 10px;
  mix-blend-mode: overlay;
}

.content-container {
  position: relative;
  z-index: 1;
}

.about-us-page h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #0073e6;
  font-family: 'Montserrat', sans-serif;
  animation: fadeIn 1s ease-in-out;
}

.about-us-page p {
  font-size: 20px;
  line-height: 1.8;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  animation: fadeIn 1s ease-in-out;
}

.about-us-page ul {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
}

.about-us-page li {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
  font-family: 'Open Sans', sans-serif;
  animation: fadeIn 1s ease-in-out;
}

.about-us-page li::before {
  content: '✔';
  position: absolute;
  left: 0;
  color: #0073e6;
  font-size: 18px;
}

.social-icons {
  margin: 20px 0;
}

.social-icons a {
  color: #0073e6;
  font-size: 24px;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #005bb5;
}

.contact-info {
  display: flex;
  justify-content: center; /* Align items center */
  align-items: center; /* Center items vertically */
  flex-wrap: wrap;
  margin-top: 20px;
}

.contact-item {
  margin: 10px;
  font-family: 'Open Sans', sans-serif;
  animation: fadeIn 1s ease-in-out;
  text-align: center; /* Center text */
}

.contact-item h3 {
  margin-bottom: 5px; /* Reduce margin */
  font-size: 16px; /* Adjust font size */
  color: #0073e6;
}

.contact-item p {
  margin: 0;
  font-size: 14px; /* Adjust font size */
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Responsive design */
@media (max-width: 768px) {
  .about-us-page h1 {
    font-size: 36px;
  }

  .about-us-page p,
  .about-us-page li,
  .contact-item p {
    font-size: 16px;
  }

  .contact-info {
    flex-direction: column;
    text-align: center; /* Center text on smaller screens */
  }

  .contact-item {
    margin: 5px; /* Reduce margin */
  }
}

@media (max-width: 480px) {
  .about-us-page {
    padding: 15px;
  }

  .about-us-page h1 {
    font-size: 28px;
  }

  .about-us-page p,
  .about-us-page li,
  .contact-item p {
    font-size: 14px;
  }
}
